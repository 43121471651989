<template>
  <c-x-features type="dashboard" class="Dashboard">
    <c-x-traits-list
      :traits="traits"
      type="dashboard"
    />
  </c-x-features>
</template>

<script>
import CXTraitsList from '@/components/cx/CXTraitsList'
import CXFeatures from '@/router/layouts/CXFeatures'

/**
 * @module Dashboard
 */
export default {
  name: 'Dashboard',
  components: { CXTraitsList, CXFeatures },
  data () {
    return {
      traits: [
        'dashboard', 'benchmark', 'monitoring',
        'mobile', 'facts', 'reports'
      ]
    }
  }
}
</script>
